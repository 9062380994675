<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      :options="options"
      @update:options="$emit('input', $event)"
      :server-items-length="itemsLength"
      :loading="loading"
      :footer-props="{itemsPerPageOptions:  [20,100,300]}"
      class="elevation-1"
      multi-sort
    >
      <template v-slot:item="{ item }">
        <tr @click="detail(item.id)">
          <td>{{item.id}}</td>
          <td>{{formatValue('users.agents', item.user_id)}}</td>
          <td>{{formatTodayDate(item.created_at)}}</td>
          <td>{{formatValue('userLog.actions', item.action)}}</td>
          <td>{{formatValue('userLog.className', item.class_name)}}</td>
          <td>
            <a
              v-if="item.action !== 3"
              @click="handleGoToEntity(item.class_name, item.entity_id)"
            >{{item.entity_id}}</a>
            <span v-else>{{item.entity_id}}</span>
          </td>
          <td v-html="shortLog(item)"></td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import headers from './header'
import formatters from '@/components/mixins/formatters'
import table from '@/components/mixins/table'
import tabActions from '@/components/mixins/tabActions'
import userLog from '@/components/mixins/userLog'

export default {
  mixins: [formatters, table, tabActions, userLog],
  name: 'Table',
  data () {
    return {
      headers
    }
  },
  methods: {
    handleGoToEntity (className, id) {
      this.add(className, 'detail', { requestParams: { id } })
      this.$router.push({ name: className })
    },
    shortLog (item) {
      let shortLog = ''
      item.logItems.forEach(logItem => {
        let fullPropertyName = this.fullPropertyName(logItem.property_name)
        if (fullPropertyName) {
          shortLog += fullPropertyName + '<br>'
        }
      })
      return shortLog
    }
  }
}
</script>

<style scoped>

</style>
